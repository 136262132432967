import { VisuallyHidden } from '@overdose/components'
import { Image } from '~/components/Image'
import { SectionContainer } from '~/components/SectionContainer'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import styles from './InstagramFeed.module.scss'
import { InstagramFeedProps } from './InstagramFeed.types'

const InstagramFeed = ({
  title,
  description,
  instagramHandle,
  instagramProfileUrl,
  images,
  width = 'full',
}: InstagramFeedProps) => {
  const imagesCount = images?.length
  return (
    <SectionContainer
      width={width}
      innerClassName='p-0'
      wrapperClassName={styles.sectionWrapper}>
      <div className='text-center w-full mx-auto flex flex-col gap-2'>
        {title && (
          <Typography
            tag={TypographyTag.h2}
            variant={TypographyVariant.Heading4}
            className={styles.title}>
            {title}
          </Typography>
        )}
        {instagramHandle && instagramProfileUrl && (
          <a href={instagramProfileUrl} target='_blank' rel='noreferrer'>
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodyRegularExtraLineHeight}
              className='!underline'>
              {instagramHandle}
            </Typography>
          </a>
        )}
        {description && (
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.BodyRegularExtraLineHeight}>
            {description}
          </Typography>
        )}
      </div>
      {imagesCount > 0 && (
        <div
          className={`${styles.imageGroup} mt-6 md:mt-10 grid grid-cols-2 md:grid-cols-3 lg:flex`}>
          {images.map(
            ({ src, altText, externalImageUrl, height, width }, index) => {
              return (
                <div key={src} className='relative aspect-1 w-full'>
                  {src && (
                    <Image
                      src={src}
                      alt={altText}
                      // width and height required for SEO
                      width={width}
                      height={height}
                      className='h-full w-full'
                      sizes={`(max-width: 767px) ${
                        index > Number('3') ? '0px' : '50vw'
                      }, (max-width: 1023px) 33vw, 20vw`}
                    />
                  )}
                  {externalImageUrl && (
                    <a
                      href={externalImageUrl}
                      target='_blank'
                      rel='noreferrer'
                      className='absolute inset-0'>
                      <VisuallyHidden>View Instagram Post</VisuallyHidden>
                    </a>
                  )}
                </div>
              )
            }
          )}
        </div>
      )}
    </SectionContainer>
  )
}

export { InstagramFeed }
